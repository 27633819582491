<template>
  <ul class="as__menu">
    <li>
      <router-link :to="{ name: 'home' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('home')}">
        <i class="as__menu__link__icon">
          <HomeIco/>
        </i>
        <span class="as__menu__link__title">Рабочий стол</span>
      </router-link>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link :to="{ name: 'tasks' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('tasks')}">
        <i class="as__menu__link__icon">
          <TaskIco/>
        </i>
        <span class="as__menu__link__title">Задачи</span>
        <span class="as__menu__link__badge as__menu__link__badge--orange" v-if="$store.state._allDoTasksMeta.count" v-tooltip="'1С'">{{ $store.state._allDoTasksMeta.count }}</span>
        <span class="as__menu__link__badge" v-else-if="$store.state.expiredTasks">{{ $store.state.expiredTasks }}</span>
      </router-link>
      <ul class="as__submenu">
        <li>
          <router-link :to="{ name: 'tasks', hash: '#table' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('tasks#table')}">
            CRM{{ $store.state.expiredTasks ? ` (${$store.state.expiredTasks})` : '' }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'tasks', hash: '#tableDo' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('tasks#tableDo')}">
            1C{{ $store.state._allDoTasksMeta.count ? ` (${$store.state._allDoTasksMeta.count})` : '' }}
          </router-link>
        </li>
      </ul>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link
        :to="{ name: 'trades' }"
        class="as__menu__link show_in_mobile"
        :class="{ active: isSubRouterLinkActive(['trades', 'memos', 'addmemo', 'calculators'])}"
      >
        <i class="as__menu__link__icon">
          <DocPlus/>
        </i>
        <span class="as__menu__link__badge" v-if="$store.state.unprocessedLeads && ($store.state.me.role === 'callcenter' || $store.state.me.role === 'admin')">{{ $store.state.unprocessedLeads }}</span>
      </router-link>
      <router-link
        :to="{ name: 'trades' }"
        :event="''"
        @click.native.prevent="mobileHandle({ name: 'trades' })"
        class="as__menu__link hide_in_mobile"
        :class="{ active: isSubRouterLinkActive(['trades', 'memos', 'addmemo'])}"
      >
        <i class="as__menu__link__icon">
          <DocPlus/>
        </i>
        <span class="as__menu__link__title">Сделки</span>
        <span class="as__menu__link__badge" v-if="$store.state.unprocessedLeads && ($store.state.me.role === 'callcenter' || $store.state.me.role === 'admin')">{{ $store.state.unprocessedLeads }}</span>
      </router-link>
      <ul class="as__submenu">
        <li>
          <router-link :to="{ name: 'trades' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('trades')}">Сделки</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'memos' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('memos')}">Служебки</router-link>
        </li>
      </ul>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link :to="{ name: 'calculators' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('calculators')}">
        <i class="as__menu__link__icon">
          <CalcIco/>
        </i>
        <span class="as__menu__link__title">Калькуляторы</span>
      </router-link>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link :to="{ name: 'property-valuation' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('property-valuation')}">
        <i class="as__menu__link__icon">
          <CarIcon/>
        </i>
        <span class="as__menu__link__title">Оценка авто</span>
      </router-link>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link :to="{ name: 'seized-properties' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('seized-properties-valuation')}">
        <i class="as__menu__link__icon">
          <TruckIcon/>
        </i>
        <span class="as__menu__link__title">Изъятое имущество</span>
      </router-link>
    </li>
    <li v-if="$store.state.me.role !== 'partner'">
      <router-link :to="{ name: 'companies' }" class="as__menu__link show_in_mobile" :class="{ active: isSubRouterLinkActive([ 'contacts', 'companies', 'directory'])}">
        <i class="as__menu__link__icon">
          <ContactIco/>
        </i>
      </router-link>
      <router-link
        :to="{ name: 'companies' }"
        :event="''"
        @click.native.prevent="mobileHandle({ name: 'companies' })"
        class="as__menu__link hide_in_mobile"
        :class="{ active: isSubRouterLinkActive([ 'contacts', 'companies', 'directory'])}"
      >
        <i class="as__menu__link__icon">
          <ContactIco/>
        </i>
        <span class="as__menu__link__title" ref="nowhere">Справочник</span>
      </router-link>
      <ul class="as__submenu">
        <li>
          <router-link :to="{ name: 'companies' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('companies')}">Компании</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'contacts' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('contacts')}">Контакты</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'directory' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('directory')}">Сотрудники</router-link>
        </li>
      </ul>
    </li>
    <li>
      <router-link :to="{ name: 'contracts' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('contracts')}">
        <i class="as__menu__link__icon">
          <DocumentsIco/>
        </i>
        <span class="as__menu__link__title">Договоры лизинга</span>
      </router-link>
    </li>
    <li v-if="$store.state.me.role === 'partner'">
      <router-link :to="{ name: 'apply' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('apply')}">
        <i class="as__menu__link__icon">
          <ApplyIco/>
        </i>
        <span class="as__menu__link__title">Подать заявку</span>
      </router-link>
    </li>
    <mq-layout :mq="['lg', 'xlg', 'xl']">
      <li v-if="[
        'Администраторы',
        'Генеральный директор',
        'Руководитель подразделения (Лизинг)'
        ]
          .filter(x => $store.state.me.roles.includes(x))
          .length
      ">
        <router-link :to="{ name: 'edo' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('edo')}">
          <i class="as__menu__link__icon">
            <DiadocIcon/>
          </i>
          <span class="as__menu__link__title">ЭДО</span>
          <span class="as__menu__link__badge" v-if="$store.state.diadocDocuments">{{ $store.state.diadocDocuments }}</span>
        </router-link>
      </li>
      <li v-if="$store.state.me.role !== 'partner'">
        <router-link :to="{ name: 'analytics' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('analytics')}">
          <i class="as__menu__link__icon">
            <DocumentationIco/>
          </i>
          <span class="as__menu__link__title">Аналитика</span>
        </router-link>
        <ul class="as__submenu" v-if="['headmanager', 'callcenter', 'admin'].includes($store.state.me.role)">
          <li>
            <router-link :to="{ name: 'analytics' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('analytics')}">Аналитика</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'diagrams' }" class="as__submenu__link" :class="{ active: isSubRouterLinkActive('diagrams')}">Диаграммы</router-link>
          </li>
        </ul>
      </li>
      <li v-if="$store.state.me.role !== 'partner'">
        <router-link :to="{ name: 'courses' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('courses')}">
          <i class="as__menu__link__icon">
            <CoursesIcon/>
          </i>
          <span class="as__menu__link__title">Курсы</span>
          <span class="as__menu__link__badge" v-if="$store.state.me.courses && $store.state.me.courses.filter(elem => !elem.completion_date).length > 0">{{ $store.state.me.courses.filter(elem => !elem.completion_date).length }}</span>
        </router-link>
      </li>
      <li v-if="this.$store.state.allFaqResponsible && this.$store.state.allFaqResponsible.includes($store.state.me._id)">
        <router-link :to="{ name: 'appeals' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('appeals')}">
          <i class="as__menu__link__icon">
            <DocumentationIco/>
          </i>
          <span class="as__menu__link__title" >Обращения</span>
          <span class="as__menu__link__badge" v-if="$store.state.unprocessesAppeals">{{ $store.state.unprocessesAppeals }}</span>
        </router-link>
      </li>
    </mq-layout>
  </ul>
</template>

<script>
import TaskIco from '@/components/svg/task'
import CalcIco from '@/components/svg/calc'
import DocumentsIco from '@/components/svg/DocumentsIco'
import DocumentationIco from '@/components/svg/documentation'
import CoursesIcon from '@/components/svg/CoursesIcon'
import HomeIco from '@/components/svg/home'
import DocPlus from '@/components/svg/doc_plus'
import ContactIco from '@/components/svg/contact'
// import CabinetIco from '@/components/svg/cabinet'
import ApplyIco from '@/components/svg/Apply'
import DiadocIcon from '@/components/svg/DiadocSideBarIcon'
import CarIcon from '@/components/svg/Car'
import TruckIcon from '@/components/svg/Truck'

export default {
  name: 'SidebarMenu',
  components: {
    DiadocIcon,
    DocumentsIco,
    CalcIco,
    DocPlus,
    ContactIco,
    TaskIco,
    HomeIco,
    DocumentationIco,
    CoursesIcon,
    // CabinetIco,
    ApplyIco,
    CarIcon,
    TruckIcon
  },
  methods: {
    isSubRouterLinkActive (routerName) {
      if (typeof routerName === 'string') {
        return routerName === this.$route.name || routerName === this.$route.meta.parent || routerName === `${this.$route.name}${this.$route.hash}`
      } else {
        return routerName.filter(item => item === this.$route.name || item === this.$route.meta.parent).length !== 0
      }
    },
    mobileHandle (route) {
      if (window.innerWidth > 768) {
        // console.log(1)
        this.$router.push(route)
      }
    }
  }
}
</script>
